import { BaseNetworkIcon, ContentWrapper, NetworkIconWrapper } from './styles';

type AssetIconProps = {
  baseUrl?: string;
  networkIcon?: JSX.Element;
  size?: string;
};
const AssetNetworkIcon: React.FC<AssetIconProps> = ({
  baseUrl,
  networkIcon,
  size = '3rem',
}) => {
  if (!networkIcon) return <BaseNetworkIcon src={baseUrl} size={size} />;
  return (
    <ContentWrapper>
      <BaseNetworkIcon src={baseUrl} size={size} />
      <NetworkIconWrapper>{networkIcon}</NetworkIconWrapper>
    </ContentWrapper>
  );
};

export default AssetNetworkIcon;
