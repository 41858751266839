import styled from 'styled-components';
import TableCell from '../table/TableCell';
import Typography from '../typography/Typography';

export const CustomMinMaxText = styled(Typography)`
  display: flex;
  flex-direction: column;
  width: max-content;
  gap: 0.5rem;
`;

export const DestinationBankName = styled(Typography)`
  white-space: pre-line;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3.8rem;
  height: 3.8rem;

  border-radius: ${({ theme }) => theme.borderRadius.highest};

  background-color: ${({ theme }) => theme.colors.interactive.secondary};
`;

export const MinMaxText = styled(Typography)`
  display: flex;
  gap: 0.5rem;
  font-size: 1.2rem;
`;

export const StyledTableCell = styled(TableCell)`
  .TableCellTitle {
    word-break: break-word;
    white-space: break-spaces;
    width: 5rem;

    ${({ theme }) => theme.media.tabletS`
     width: 9.2rem;
  `}
  }
`;

export const TextWrapperDesktop = styled.div`
  display: none;
  ${({ theme }) => theme.media.tablet`
      display: block;
  `}
`;

export const TextWrapperDesktopBreakSpace = styled.div`
  display: none;
  ${({ theme }) => theme.media.tablet`
      display: block;
      white-space: break-spaces;
  `}
`;

export const TextWrapperMobile = styled.div`
  display: block;
  ${({ theme }) => theme.media.tablet`
      display: none;
  `}
`;

export const FlexWrapper = styled.div`
  display: flex;
  gap: 2rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
