import { FC } from 'react';
import { TextType } from '../../typography/styles';
import { MinMaxText, TextWrapperDesktop, TextWrapperMobile } from '../styles';

type TbcTableFeeProps = {
  defaultColor?: string;
  textType: TextType;
  desktopFirstText: string;
  desktopSecondText: string;
  mobileFirstText: string;
  mobileSecondText: string;
};

export const TableFiatFee: FC<TbcTableFeeProps> = ({
  textType,
  defaultColor,
  desktopFirstText,
  desktopSecondText,
  mobileFirstText,
  mobileSecondText,
}) => {
  return (
    <MinMaxText type={textType} fontFamily="primaryMedium" color={defaultColor}>
      <TextWrapperDesktop>{desktopFirstText}</TextWrapperDesktop>
      <TextWrapperMobile>{mobileFirstText}</TextWrapperMobile>
      <TextWrapperDesktop>{desktopSecondText}</TextWrapperDesktop>
      <TextWrapperMobile>{mobileSecondText}</TextWrapperMobile>
    </MinMaxText>
  );
};
