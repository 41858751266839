import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { BankCode, BankName } from '@libs/backend-common';
import { screenSizes } from '@libs/theme';
import { WithdrawalMethods } from '@libs/types';

import {
  formatAmountWithSymbol,
  getEstimatedAmount,
  getUsdOrEurCurrencySymbol,
} from '@libs/helpers';

import Typography from '../typography/Typography';
import TableCell from '../table/TableCell';
import AssetNetworkIcon from '../asset-network-icon/AssetNetworkIcon';
import { TablesData, LandingFeesTableProps } from './types';

import { DestinationBankName, FlexWrapper, TextWrapper } from './styles';

import { useTablesForMobileScreen } from './feesTableHooks/useTablesForMobileScreen';
import { useFeesTables } from './feesTableHooks/useFeesTable';
import { useLandingFeesMobileTables } from './feesTableHooks/useLandingFeesMobileTable';

type AssetCodeCountsType = { [key: string]: number };

export const useLandingFeesTable = (
  landingFeesTableProps: LandingFeesTableProps
) => {
  const { colors } = useTheme();
  const mobileScreen = landingFeesTableProps.width < screenSizes.tablet;

  const textType =
    landingFeesTableProps.width <= screenSizes.tablet
      ? 'caption'
      : 'bodyButton';

  const formatBankDestination = useCallback(
    (bankName?: string) => {
      if (bankName) {
        return BankName[bankName as BankCode];
      } else {
        return landingFeesTableProps.anyGeorgianBank;
      }
    },
    [landingFeesTableProps.anyGeorgianBank]
  );

  const { mobileTables } = useLandingFeesMobileTables({
    withdrawMeth: landingFeesTableProps.withdrawMeth,
    commonCurrency: landingFeesTableProps.commonCurrency,
    commonFee: landingFeesTableProps.commonFee,
    currencyGelOrEur: landingFeesTableProps.currencyGelOrEur,
    tableProccesing: landingFeesTableProps.tableProccesing,
    commonInstant: landingFeesTableProps.commonInstant,
    commonLimit: landingFeesTableProps.commonLimit,
    commonUnlimited: landingFeesTableProps.commonUnlimited,
    currencyUsd: landingFeesTableProps.currencyUsd,
    currencyGelUsd: landingFeesTableProps.currencyGelUsd,
    isJurisdictionGe: landingFeesTableProps.isJurisdictionGe,
    width: landingFeesTableProps.width,
    walletActionDep: landingFeesTableProps.walletActionDep,
    commonCard: landingFeesTableProps.commonCard,
    CardIcon: landingFeesTableProps.CardIcon,
    BankIcon: landingFeesTableProps.BankIcon,
    commonBankwireBogOrSepa: landingFeesTableProps.commonBankwireBogOrSepa,
    commonBankwireOther: landingFeesTableProps.commonBankwireOther,
    defaultColor: colors.text.default,
    brandColor: colors.interactive.brandColor,
    commonMeth: landingFeesTableProps.commonMeth,
    commonBankwireTbc: landingFeesTableProps.commonBankwireTbc,
    bankWireBogDeposit: landingFeesTableProps.bankWireBogDeposit,
    bankWireTbcDeposit: landingFeesTableProps.bankWireTbcDeposit,
  });

  const {
    // internalTransferData,
    // cardWithdrawalData,
    // bankWireWithdrawalData,
    cryptoWithdrawalData,
  } = useMemo(() => {
    if (!landingFeesTableProps.withdrawalFeesRes?.getAllWithdrawalFees)
      return {
        internalTransferData: [],
        cardWithdrawalData: [],
        bankWireWithdrawalData: [],
        cryptoWithdrawalData: [],
      };

    const Amount = (
      <Typography
        type={textType}
        fontFamily="primaryMedium"
        color={colors.text.default}
      >
        {landingFeesTableProps.commonUnlimited}
      </Typography>
    );

    const assetCodeCounts: AssetCodeCountsType =
      landingFeesTableProps.filteredSupportedAssets.reduce((acc, item) => {
        if (item.method === WithdrawalMethods.FIREBLOCKS) {
          acc[item.assetCode] = (acc[item.assetCode] || 0) + 1;
        }
        return acc;
      }, {} as AssetCodeCountsType);

    return landingFeesTableProps.filteredSupportedAssets.reduce(
      (acc, fee) => {
        const amount = Number(fee.fixed).toLocaleString('en-US', {
          maximumFractionDigits: 4,
        });

        const Asset = (
          <TableCell
            title={fee.assetName}
            description={fee.assetCode}
            img={fee.iconUrl}
          />
        );

        const Fee = (
          <Typography
            type={textType}
            fontFamily="primaryMedium"
            color={colors.text.default}
          >
            {amount}
          </Typography>
        );

        const BlockChainFee = (
          <Typography
            type={textType}
            fontFamily="primaryMedium"
            color={colors.text.default}
          >
            {getEstimatedAmount(fee.blockchainFee || 0)}
          </Typography>
        );

        const Price = (
          <Typography
            type={textType}
            fontFamily="primaryMedium"
            color={colors.text.default}
          >
            {formatAmountWithSymbol({
              amount: fee.price,
              symbol: getUsdOrEurCurrencySymbol(),
              shouldHaveSpace: false,
            })}
          </Typography>
        );

        const Destination = (
          <DestinationBankName
            type={textType}
            fontFamily="primaryMedium"
            color={colors.text.default}
          >
            {formatBankDestination(fee.extraConditions?.destinationBankCode)}
          </DestinationBankName>
        );

        if (fee.isCrypto && fee.method === WithdrawalMethods.BITNET_INTERNAL) {
          acc.internalTransferData.push({
            asset: (
              <TableCell
                title={fee.assetName.split(' ').splice(0, 2).join(' ')}
                description={fee.assetCode}
                img={fee.iconUrl}
              />
            ),
            fee: Fee,
            amount: Amount,
          });
        }

        if (fee.isCrypto && fee.method === WithdrawalMethods.FIREBLOCKS) {
          let networkIcon;
          let assetCode = fee.assetCode;

          if (assetCodeCounts[fee.assetCode] > 1) {
            const network = landingFeesTableProps.networkResp?.getNetworks.find(
              (item) =>
                Number(item.id) === fee.extraConditions?.destinationNetwork
            );

            if (network) {
              networkIcon = network.iconUrl;
              assetCode = `${fee.assetCode} (${network.name})`;
            }
          }

          acc.cryptoWithdrawalData.push({
            asset: (
              <FlexWrapper>
                <AssetNetworkIcon
                  baseUrl={fee.iconUrl}
                  networkIcon={<img src={networkIcon} width={15} />}
                />
                <TextWrapper>
                  <Typography type="bodyButton" fontFamily="primaryBold">
                    {fee.assetName}
                  </Typography>
                  <Typography type="caption" fontFamily="primary">
                    {mobileScreen
                      ? ` ${formatAmountWithSymbol({
                          amount: fee.price,
                          symbol: getUsdOrEurCurrencySymbol(),
                        })}`
                      : assetCode}
                  </Typography>
                </TextWrapper>
              </FlexWrapper>
            ),
            fee: Fee,
            blockchainFee: BlockChainFee,
            amount: Amount,
            price: Price,
          });
        }

        if (!fee.isCrypto && fee.method === WithdrawalMethods.BOG_WIRE) {
          acc.bankWireWithdrawalData.push({
            asset: Asset,
            fee: Fee,
            amount: Amount,
            destination: Destination,
          });
        }

        if (!fee.isCrypto && fee.method === WithdrawalMethods.BOG_CARD) {
          acc.cardWithdrawalData.push({
            asset: Asset,
            fee: Fee,
            amount: Amount,
            destination: Destination,
          });
        }
        return acc;
      },
      {
        internalTransferData: [],
        cardWithdrawalData: [],
        bankWireWithdrawalData: [],
        cryptoWithdrawalData: [],
      } as TablesData
    );
  }, [
    landingFeesTableProps.withdrawalFeesRes?.getAllWithdrawalFees,
    landingFeesTableProps.commonUnlimited,
    landingFeesTableProps.filteredSupportedAssets,
    landingFeesTableProps.networkResp?.getNetworks,
    textType,
    colors.text.default,
    formatBankDestination,
    mobileScreen,
  ]);

  const slicedFilteredCryptoWithdrawalData =
    landingFeesTableProps.fullyLoaded &&
    !landingFeesTableProps.cryptoWithdrawSearchValue
      ? cryptoWithdrawalData.slice(0, 5)
      : cryptoWithdrawalData;

  const { tables } = useFeesTables({
    landingFeesWithdrawalMethods:
      landingFeesTableProps.landingFeesWithdrawalMethods,
    commonCurrency: landingFeesTableProps.commonCurrency,
    commonFee: landingFeesTableProps.commonFee,
    currencyGelOrEur: landingFeesTableProps.currencyGelOrEur,
    tableProccesing: landingFeesTableProps.tableProccesing,
    commonInstant: landingFeesTableProps.commonInstant,
    commonLimit: landingFeesTableProps.commonLimit,
    commonUnlimited: landingFeesTableProps.commonUnlimited,
    currencyUsd: landingFeesTableProps.currencyUsd,
    currencyGelUsd: landingFeesTableProps.currencyGelUsd,
    isJurisdictionGe: landingFeesTableProps.isJurisdictionGe,
    tableAssets: landingFeesTableProps.tableAssets,
    width: landingFeesTableProps.width,
    tableBitnetFee: landingFeesTableProps.tableBitnetFee,
    tableBlockchainFee: landingFeesTableProps.tableBlockchainFee,
    tableAmount: landingFeesTableProps.tableAmount,
    tableAmountShort: landingFeesTableProps.tableAmountShort,
    landingFeesFundingOptions: landingFeesTableProps.landingFeesFundingOptions,
    shouldRender: landingFeesTableProps.shouldRender,
    walletActionDep: landingFeesTableProps.walletActionDep,
    commonCard: landingFeesTableProps.commonCard,
    CardIcon: landingFeesTableProps.CardIcon,
    BankIcon: landingFeesTableProps.BankIcon,
    commonBankwireBogOrSepa: landingFeesTableProps.commonBankwireBogOrSepa,
    landingFeesCryptoWithdrawalFees:
      landingFeesTableProps.landingFeesCryptoWithdrawalFees,
    cryptoWithdrawalData: slicedFilteredCryptoWithdrawalData,
    landingFeesCryptoWithdrawalMethodsDisclaimer:
      landingFeesTableProps.landingFeesCryptoWithdrawalMethodsDisclaimer,
    landingFeesFundingDisclaimer:
      landingFeesTableProps.landingFeesFundingDisclaimer,
    commonBankwireOther: landingFeesTableProps.commonBankwireOther,
    landingFeesWithdrawalMethodsDisclaimer:
      landingFeesTableProps.landingFeesWithdrawalMethodsDisclaimer,
    defaultColor: colors.text.default,
    brandColor: colors.interactive.brandColor,
    processingTime: landingFeesTableProps.processingTime,
    commonUnlimitedShort: landingFeesTableProps.commonUnlimitedShort,
    tableDestinationBank: landingFeesTableProps.tableDestinationBank,
    withdrawalFeesRes: landingFeesTableProps.withdrawalFeesRes,
    commonMeth: landingFeesTableProps.commonMeth,
    commonBankwireTbc: landingFeesTableProps.commonBankwireTbc,
    bankWireBogDeposit: landingFeesTableProps.bankWireBogDeposit,
    bankWireTbcDeposit: landingFeesTableProps.bankWireTbcDeposit,
    tablePrice: landingFeesTableProps.tablePrice,
  });

  const { tablesForMobileScreen } = useTablesForMobileScreen({
    convertFee: landingFeesTableProps.convertFee,
    landingFeesWithdrawalMethods:
      landingFeesTableProps.landingFeesWithdrawalMethods,
    withdrawMeth: landingFeesTableProps.withdrawMeth,
    commonWithdrawMethods: landingFeesTableProps.commonWithdrawMethods,
    commonCurrency: landingFeesTableProps.commonCurrency,
    commonFee: landingFeesTableProps.commonFee,
    currencyGelOrEur: landingFeesTableProps.currencyGelOrEur,
    tableProccesing: landingFeesTableProps.tableProccesing,
    commonInstant: landingFeesTableProps.commonInstant,
    commonLimit: landingFeesTableProps.commonLimit,
    commonUnlimited: landingFeesTableProps.commonUnlimited,
    currencyUsd: landingFeesTableProps.currencyUsd,
    currencyGelUsd: landingFeesTableProps.currencyGelUsd,
    isJurisdictionGe: landingFeesTableProps.isJurisdictionGe,
    tableAssets: landingFeesTableProps.tableAssets,
    width: landingFeesTableProps.width,
    tableBitnetFee: landingFeesTableProps.tableBitnetFee,
    tableBlockchainFee: landingFeesTableProps.tableBlockchainFee,
    tableAmount: landingFeesTableProps.tableAmount,
    tableAmountShort: landingFeesTableProps.tableAmountShort,
    walletActionDeposit: landingFeesTableProps.walletActionDeposit,
    landingFeesFundingOptions: landingFeesTableProps.landingFeesFundingOptions,
    shouldRender: landingFeesTableProps.shouldRender,
    walletActionDep: landingFeesTableProps.walletActionDep,
    commonCard: landingFeesTableProps.commonCard,
    CardIcon: landingFeesTableProps.CardIcon,
    BankIcon: landingFeesTableProps.BankIcon,
    commonBankwireBogOrSepa: landingFeesTableProps.commonBankwireBogOrSepa,
    withdrawCryptoFee: landingFeesTableProps.withdrawCryptoFee,
    landingFeesCryptoWithdrawalFees:
      landingFeesTableProps.landingFeesCryptoWithdrawalFees,
    cryptoWithdrawalData: slicedFilteredCryptoWithdrawalData,
    landingFeesCryptoWithdrawalMethodsDisclaimer:
      landingFeesTableProps.landingFeesCryptoWithdrawalMethodsDisclaimer,
    landingFeesFundingDisclaimer:
      landingFeesTableProps.landingFeesFundingDisclaimer,
    commonBankwireOther: landingFeesTableProps.commonBankwireOther,
    landingFeesWithdrawalMethodsDisclaimer:
      landingFeesTableProps.landingFeesWithdrawalMethodsDisclaimer,
    brandColor: colors.interactive.brandColor,
    defaultColor: colors.text.default,
    commonBankwireTbc: landingFeesTableProps.commonBankwireTbc,
    bankWireBogDeposit: landingFeesTableProps.bankWireBogDeposit,
    bankWireTbcDeposit: landingFeesTableProps.bankWireTbcDeposit,
  });

  return {
    tables,
    mobileTables,
    tablesForMobileScreen,
    cryptoWithdrawSearchValue: landingFeesTableProps.cryptoWithdrawSearchValue,
    setCryptoWithdrawSearchValue:
      landingFeesTableProps.setCryptoWithdrawSearchValue,
    fullyLoaded: landingFeesTableProps.fullyLoaded,
    setFullyLoaded: landingFeesTableProps.setFullyLoaded,
  };
};
