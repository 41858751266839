import { formatBalance } from '@libs/helpers';

const MIN_FOUNDING_OPTION_WITH_CARD = 2;
const MIN_FOUNDING_OPTION_WITH_BANKWIRE = 0;

const MIN_FOUNDING_OPTION_WITH_CARD_WITHDRAW = 1;
const MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_GEL = 0;
const MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_EUR = 0.2;
const MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_USD = 0;

const MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL = 1000;
const MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL_MEDIUM = 10000;
const MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL_MAX = 100000;

const MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_USD = 0.2;

const MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL_FEE = 1;
const MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL_MEDIUM_FEE = 5;
const MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL_MAX_FEE = 10;

export const minFoundingOptwithCard = formatBalance(
  MIN_FOUNDING_OPTION_WITH_CARD
);
export const minFoundingOptWithBankWireGel = formatBalance(
  MIN_FOUNDING_OPTION_WITH_BANKWIRE
);
export const minFoundingOptWithBankWireUsd = formatBalance(
  MIN_FOUNDING_OPTION_WITH_BANKWIRE
);
export const minFoundingOptWithCardWithdraw = formatBalance(
  MIN_FOUNDING_OPTION_WITH_CARD_WITHDRAW
);
export const minFoundingOptWithBankwireWithdrawGel = formatBalance(
  MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_GEL
);
export const minFoundingOptWithBankwireWithdrawUsd = formatBalance(
  MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_USD
);
export const minFoundingOptWithBankwireWithdrawEur =
  MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_EUR;
export const minFoundingOptWithBankwireWithdrawTbcGelWithFee =
  MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL +
  ': ' +
  formatBalance(MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL_FEE);
export const minFoundingOptWithBankwireWithdrawTbcGelMediumWithFee =
  MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL_MEDIUM +
  ': ' +
  formatBalance(MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL_MEDIUM_FEE);
export const minFoundingOptWithBankwireWithdrawTbcGelMaxWithFee =
  MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL_MAX +
  ': ' +
  formatBalance(MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL_MAX_FEE);
export const minFoundingOptWithBankwireWithdrawTbcUsd = formatBalance(
  MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_USD
);

export const minFundingOptionCardOtherBank = `${formatBalance(
  MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL_MEDIUM
)}  > ${formatBalance(
  MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL_MEDIUM_FEE
)}`;

export const minFundingOptionsWithBankWireOtherBankGel = ` ${MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL}  - `;

export const minFundingOptionsWithBankWireOtherBankGelLarge = `${MIN_FOUNDING_OPTION_WITH_BANKWIRE_WITHDRAW_TBC_GEL_MEDIUM}
  - `;
