import styled from 'styled-components';

export default styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``;

export type SvgIconDefaultProps = {
  // className is provided so that we can override styles with styled-components
  // syntax: styled(Icon)`width: 2rem;`
  id?: string;
  className?: string;
  testId?: string;
  color?: string;
  width?: number;
  height?: number;
};
