import styled from 'styled-components';

type BaseAssetNetworkProps = {
  size: string;
};
export const ContentWrapper = styled.div`
  width: min-content;
  position: relative;
`;
export const BaseNetworkIcon = styled.img<BaseAssetNetworkProps>`
  height: ${({ size }) => `${size};`};
  width: ${({ size }) => `${size};`};
  z-index: ${({ theme }) => theme.zIndex.medium};
`;
export const NetworkIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  padding: 0.2rem;
  background-color: ${({ theme }) => theme.colors.surface.default};
  top: -0.55rem;
  right: -0.7rem;
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  svg {
    height: 1.6rem;
    width: 1.6rem;
  }
`;
